// Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase,
// DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper,
// SplitText, CustomBounce, CustomWiggle,
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
export default () => {
  //------------------------------------------------------//
  // Setup 🧦 GSAP and register 📜 ScrollTrigger
  // Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
  // Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
  //------------------------------------------------------//
  // gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);
  let mm = gsap.matchMedia();

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers: window.location.hostname.endsWith(".local") ? true : false,
  });

  // END Setup 🧦 GSAP -------------------------------------//

  //------------------------------------------------------//
  // Animation random ornaments
  //------------------------------------------------------//
  document.querySelectorAll(".has-ornaments").forEach(function (container) {
    // Create ornaments container
    const ornaments = document.createElement("ul");
    ornaments.classList.add("ornaments");
    container.appendChild(ornaments);

    // Setup
    const amount = gsap.utils.random(2, 4);
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const items = [];

    for (let i = 0; i < amount; i++) {
      const item = document.createElement("li");
      const random = {
        class: gsap.utils.wrap(["one", "three", "five"], i),
        size: gsap.utils.wrap(["small", "medium", "large"], i),
      };
      gsap.set(item, { rotation: "random(0, 360)" });
      ornaments.appendChild(item);
      items.push(item);
      item.classList.add(random.class);
      item.classList.add(random.size);
      // item.classList.add(random.size);
      // const randomEdge = gsap.utils.wrap([0, 1], i);
      let fromEdge = gsap.utils.random(-50, containerWidth * 0.05);
      mm.add("(max-width: 2000px)", () => {
        fromEdge = gsap.utils.random(-50, -100);
      });
      // Select random edge
      // if odd
      if (i % 2) {
        item.style.top = "50%";
        item.style.left = `${fromEdge}px`;
        console.warn('odd');
      } else {
        item.style.top = "50%";
        item.style.right = `${fromEdge}px`;
        console.warn('even');
      }
    }

    // GSAP  ScrollTrigger
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: container, // What element triggers the scroll
        scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
        start: "top bottom", // Can be top, center, bottom
      },
    });

    // console.warn(items);

    timeline.to(items, {
      // Get the max scroll height and get a random percentage from that value
      y: (index) =>
        -(container.offsetHeight / 2) *
        gsap.utils.wrap([1.2, 0.8, 0.4, 0.1], index),
      // // Disable default easing for a smoother scroll
      ease: "none",
    });
  });
  // END Animation random ornaments -------------------------------------//
  document.querySelectorAll(".ACF-photo_grid").forEach((container) => {
    const q = gsap.utils.selector(container);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: "top bottom-=10%",
        end: "bottom top",
      },
    });

    tl.from(q(".item"), {
      clipPath: "polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)",
      stagger: {
        each: 0.1,
        from: "random",
      },
    });
  });
  document.querySelectorAll(".ACF-kenmerken").forEach(function (container) {
    const q = gsap.utils.selector(container); // scope to current
    const move = 100;
    const duration = 1;
    // const morph = gsap.utils.wrap(q(".morph"));
    // const text = q(".text > *");
    // const textWrap = gsap.utils.wrap(text);
    const bg = q("li");
    const bgWrap = gsap.utils.wrap(bg);
    //--------------------------------//
    // Setup
    //--------------------------------//
    // gsap.set(bg, {
    //   xPercent: -move
    // });
    gsap.set(bg[3], {
      xPercent: -move,
    });
    gsap.set(bg[0], {
      xPercent: 0,
    });
    gsap.set(bg[1], {
      xPercent: move,
    });
    gsap.set(bg[2], {
      xPercent: move * 2,
    });

    // END Setup --------------//

    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 1,
      defaults: {
        duration: duration,
        ease: "none",
      },
    });

    const zIndexArray = [200, 300, 400, 100]; // z-index logic for each item

    bg.forEach((item, index) => {
      // Apply z-index logic
      tl.set(
        [
          bgWrap(index),
          bgWrap(index + 1),
          bgWrap(index + 2),
          bgWrap(index + 3),
        ],
        { zIndex: (index) => zIndexArray[index] },
      );
      // Move seocnd one in to view and at the same time the thrid one
      tl.to(bgWrap(index + 1), { xPercent: `-=${move}` });
      tl.to(bgWrap(index + 2), { xPercent: `-=${move}` }, "<");
      // With a delay move the first one.
      tl.to(bgWrap(index), { xPercent: `-=${move}` }, `-=${duration / 2}`);
      // Instanly move the the last one to its postion
      tl.set(bgWrap(index + 3), { xPercent: move * 2 });
      // Add a smalll pause between slides
      tl.add(() => { }, `+=${duration * 2}`);
    });

  });
  // END Animation ELEMENT -------------------------------------//
};
